<template>
  <div>
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="周期任务" name="first">
        <collec></collec>
      </el-tab-pane>
      <el-tab-pane label="紧急任务" name="second">
        <sample></sample>
      </el-tab-pane>
    </el-tabs> -->
    <el-radio-group v-model="activeName" size="medium" style="margin-bottom:20px">
      <el-radio-button label="first">周期任务</el-radio-button>
      <el-radio-button label="second">紧急任务</el-radio-button>
    </el-radio-group>
    <collec v-if="activeName == 'first'"></collec>
    <sample v-if="activeName == 'second'"></sample>
  </div>
</template>
<script>
import collec from './collec.vue'
import sample from './sample.vue'
export default {
  name: 'Index',
  components: { collec , sample },
  data() {
    return {
      activeName: 'first',
      options:[],
      value:''
    }
  },
   mounted() {
    if(this.$route.query.screcd == 1) {
      this.activeName = 'first'
    }
    if(this.$route.query.screcd == 2) {
      this.activeName = 'second'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
</style>
